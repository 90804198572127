<template>
    <GMapMap
        :center="state.center"
        :zoom="13"
        map-type-id="terrain"
        style="width:100%;height:60rem;">
        <GMapMarker
            v-for="(item, index) in state.locations"
            :key="index"
            :position="item.position"
            @click="toggleActive(index)">
            <GMapInfoWindow
                :opened="item.isActive"
                @closeclick="item.isActive = false"
                :closeclick="true">
                <div>
                    <p><strong>{{ item.title }}</strong></p>
                </div>
            </GMapInfoWindow>
        </GMapMarker>

        <GMapPolyline
            :path="state.locations.map(item => item.position)"
            :options="{
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 2,
            }"/>
    </GMapMap>
</template>

<script>
import {
    defineComponent,
    reactive,
} from 'vue'
import forEach from 'lodash/forEach'


export default defineComponent({
    setup: () => {
        const state = reactive({
            center: {lat: 0.5197415, lng: 101.4375358},
            locations: [
                {
                    title: 'Mal SKA',
                    position: { lat: 0.499370, lng: 101.418255 },
                    isActive: false,
                },
                {
                    title: 'Living World Pekanbaru',
                    position: { lat: 0.500121, lng: 101.420106 },
                    isActive: false,
                },
                {
                    title: 'Mal Pekanbaru',
                    position: { lat: 0.531970, lng: 101.448297 },
                    isActive: false,
                },
            ],
        })

        const toggleActive = (index) => {
            // Reset semua isActive ke false
            forEach(state.locations, (item, idx) => {
                item.isActive = idx === index ? !item.isActive : false;
            });
        }

        return {
            state,
            toggleActive,
        }
    },
})
</script>